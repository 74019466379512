import React, { useEffect, useState } from 'react'
import {
    Typography,
    ButtonGroup,
    Button
    // Grid,
    // IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// import React3DRotationBox from './React3DRotationBox.js'
import { TYPE_ROBOT, changeTypeRobot } from '../../../configs'


const useStyles = makeStyles(p => ({
    root: {
        // backgroundColor: '#000000',
        backgroundImage: 'url(/images/technicalBG.png)',
        position: 'relative',
        // textAlign: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: p.spacing(22,0)
    },
    contentRoot: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        // paddingTop: p.spacing(10),
        color: '#3A5BF5',
        fontSize: '48px',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        [p.breakpoints.down('md')]: {
            fontSize: '44px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px'
        },
        // '&:first-letter': {
        //     color: '#254CFF',
        //     fontWeight: 'bold',
        // }
    },
    content: {
        paddingTop: p.spacing(7),
        paddingLeft: p.spacing(3),
        paddingRight: p.spacing(3),
        minHeight: 600
    },
    contentLeft: {
        position: 'relative',
        [p.breakpoints.down('sm')]: {
            position: 'static'
        },
    },
    imageHardware: {
        position: 'absolute',
        top: 250,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        [p.breakpoints.down('md')]: {
            position: 'static',
            width: '100%',
        },
    },
    imageNoRotate: {
        display: 'block',
        margin: '150px auto 50px auto',
    },
    contentRight: {
        padding: p.spacing(5),
        textAlign: 'left'
    },
    itemContentRight: {
        marginBottom: p.spacing(3)
    },
    itemContentRightNoRotate: {
        marginBottom: p.spacing(3),
        marginTop: p.spacing(18)
    },
    titleText: {
        color: '#F2F2F2',
        fontSize: '14px',
        fontWeight: 'bold',
        width: '500px',
        margin: '0 auto',
        display: 'inline',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '13px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '11px',
            width: '350px',
        },
    },
    contentText: {
        color: '#F2F2F2',
        fontSize: '14px',
        width: '500px',
        margin: '0 auto',
        textAlign: 'left',
        display: 'inline',
        whiteSpace: 'pre-wrap',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '13px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '11px',
            width: '350px',
        },
    },

    iconButton: {
        margin: p.spacing(8,8,4,8)
    },
    imgIconButton: {
        height: 10
    },
    radiusCover: {
        width: '100%',
        marginBottom: '-5px',
    },
    contentTechnical: {
        marginTop: p.spacing(10),
        display: 'flex',
        justifyContent: 'space-between',
        [p.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: p.spacing(0),
        },
    },
    backgroundHardware: {
        height: 800,
        flexGrow: 10,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [p.breakpoints.down('xs')]: {
            height: 400,
        },
    },
    buttonGroup: {
        display: 'flex'
    },
    contentButtonGroup: {
        transform: 'rotate(90deg)',
        [p.breakpoints.down('xs')]: {
            margin: 'auto',
            transform: 'none',
        },
    },
    switchButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#ABABAB',
        height: 'fit-content',
        margin:' auto'
    },
    switchSelectedButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#254CFF',
        height: 'fit-content',
        margin:' auto'
    },
}))

const beetle = {
    name: "BeetleBot",
    image: 'url(/images/detailBeetle.png)'
}

const healer = {
    name: "HealerBot",
    image: 'url(/images/detailHealer.png)'
}

const Hardware = () => {

    const classes = useStyles()

    // const [indexState, setIndexState] = useState(0)

    // const contentHardware = [
    //     {
    //         title: 'MATERIAL',
    //         content: 'Glass Fiber Composite cover, Aluminum frame',
    //     },
    //     {
    //         title: 'DIMENSION',
    //         content: 'L750 x W550 x H600',
    //     },
    //     {
    //         title: 'WEIGHT (with battery)',
    //         content: '60 kg',
    //     },
    //     {
    //         title: 'ENVIRONMENT',
    //         content: 'Indoor and outdoor, complex terrain, Slope < 40 degree, sidewalk step < 120mm, temperature 5 to 40 degree',
    //     },
    //     {
    //         title: 'PAYLOAD',
    //         content: '< 40 kg',
    //     },
    //     {
    //         title: 'MOBILITY',
    //         content: 'Max Speed 2500 mm/s, Rotation Speed 90 degree/s',
    //     },
    //     {
    //         title: 'BATTERY',
    //         content: '22-28 VDC, 72 Ah capacity, Lithium Ion, 10 hours run time',
    //     },
    //     {
    //         title: 'BATTERY LIFE',
    //         content: '2000 recharge cycles',
    //     },
    //     {
    //         title: 'RECHARGE TIME',
    //         content: '5 hours',
    //     },
    //     {
    //         title: 'CHARGING METHOD',
    //         content: 'Manual',
    //     },
    // ]

    // const listHardware = [
    //     {
    //         id: 0,
    //         image: '/images/hardware/overview-robot.png',
    //         content: contentHardware
    //     },
    //     {
    //         id: 1,
    //         image: '/images/hardware/robot-front.png',
    //         content: contentHardware
    //     },
    //     {
    //         id: 2,
    //         image: '/images/hardware/robot-top.png',
    //         content: contentHardware
    //     },
    //     {
    //         id: 3,
    //         image: '/images/hardware/robot-back.png',
    //         content: contentHardware
    //     },
    //     {
    //         id: 4,
    //         image: '/images/hardware/wheel.png',
    //         content: [
    //             {
    //                 title: 'WHEELs',
    //                 content: '\nWheels drive, 4 Steering drive \nDiameter: 150mm \nMaterial : Rubber',
    //             }
    //         ]
    //     },
    //     {
    //         id: 5,
    //         image: '/images/hardware/stop-button.png',
    //         content: [
    //             {
    //                 title: 'EMERGENCY STOP BUTTON',
    //                 content: ''
    //             }
    //         ]
    //     },
    //     {
    //         id: 6,
    //         image: '/images/hardware/camera.png',
    //         content: [
    //             {
    //                 title: 'FRONT CAMERA',
    //                 content: ''
    //             }
    //         ]
    //     },
    // ]

    // const handleBack = () => {
    //     if (indexState === 0) {
    //         setIndexState(6)
    //     } else {
    //         setIndexState(prevIndexState => prevIndexState - 1)
    //     }
    // }

    // const handleNext = () => {
    //     if (indexState === 6) {
    //         setIndexState(0)
    //     } else {
    //         setIndexState(prevIndexState => prevIndexState + 1)
    //     }
    // }

    const [data, setData] = useState(beetle)

    useEffect(() => {
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetle : healer)

        }, 1000);
    }, [])

    const handleChangeTypeRobot = () => {
        changeTypeRobot();
        setData(TYPE_ROBOT === "BeetleBot" ? beetle : healer)
    }

    return (
        <div id="hardware" className={classes.root}>
            <div className={classes.contentRoot}>
            <Typography className={classes.title}>
                Technical
            </Typography>
            <div className={classes.contentTechnical}>
                <div className={classes.backgroundHardware} style={{backgroundImage: data.image}}>

                </div>
                <div className={classes.buttonGroup}>
                    <ButtonGroup
                        aria-label="vertical outlined primary button group"
                        className={classes.contentButtonGroup}
                    >
                        <Button
                            onClick={handleChangeTypeRobot}
                            className={data.name=== "BeetleBot"?classes.switchSelectedButton:classes.switchButton}
                        >
                            BettleBot
                        </Button>
                        <Button
                            onClick={handleChangeTypeRobot}
                            className={data.name=== "HealerBot"?classes.switchSelectedButton:classes.switchButton}
                        >
                            HealerBot
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
           
            {/* <Grid container justify="space-around" className={classes.content}>
                <Grid item lg={5} className={classes.contentLeft} data-aos="zoom-in">
                    {
                        (indexState === 0 || indexState === 1 || indexState === 2 || indexState === 3) &&
                        // <React3DRotationBox
                        //     perspective="300px"
                        //     rotateForce={30}
                        // >
                            <img src={listHardware[indexState].image} alt="robot" className={classes.imageNoRotate}/>
                        // </React3DRotationBox>
                    }
                    {
                        (indexState === 4 || indexState === 5 || indexState === 6) &&
                            <img src={listHardware[indexState].image} alt="robot" className={classes.imageNoRotate}/>
                    }
                    <IconButton size={"small"} onClick={() => handleBack()} className={classes.iconButton}>
                        <img src={'/images/hardware/arrow-left.png'} alt="Error" className={classes.imgIconButton} />
                    </IconButton>
                    <IconButton size={"small"} onClick={() => handleNext()} className={classes.iconButton}>
                        <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />
                    </IconButton>
                </Grid>
                {(indexState === 0 || indexState === 1 || indexState === 2 || indexState === 3) &&
                    <Grid item lg={7} className={classes.contentRight} data-aos="fade-up-left">
                        {
                            listHardware[indexState].content && listHardware[indexState].content.map(item => (
                                <div className={classes.itemContentRight} key={item.title}>
                                    <Typography className={classes.titleText}>{item.title} : </Typography>
                                    <Typography className={classes.contentText}>{item.content}</Typography>
                                </div>
                        ))}
                    </Grid>
                }
                {(indexState === 4 || indexState === 5 || indexState === 6) &&
                    <Grid item lg={7} className={classes.contentRight} data-aos="fade-up-left">
                        {
                            listHardware[indexState].content && listHardware[indexState].content.map(item => (
                                <div className={classes.itemContentRightNoRotate} key={item.title}>
                                    <Typography className={classes.titleText}>{item.title}</Typography>
                                    <Typography className={classes.contentText}>{item.content}</Typography>
                                </div>
                        ))}
                    </Grid>
                }
            </Grid> */}
            </div>
        </div>
    )
}

export default Hardware
