import React, {useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'
import { TYPE_ROBOT } from '../../../configs'

const useStyles = makeStyles(p => ({
    root: {
        backgroundImage: 'url(/images/howItWorks.png)',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundSize: 'cover',
        // textAlign: 'center',
        width: '100%',
        height: '100%',
        padding: p.spacing(10,0)
    },
    contentRoot: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        color: '#3A5BF5',
        fontSize: '48px',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        paddingTop: p.spacing(15),
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(10),
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    questionIcon: {
        color: '#254CFF',
        fontSize: '48px',
        [p.breakpoints.down('md')]: {
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    decribe: {
        color: '#F2F2F2',
        fontSize: '16px',
        width: '500px',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '16px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '15px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '14px',
            width: '350px',
        },
    },
    strongBlue: {
        color: '#254CFF',
        fontWeight: 'bold',
        fontSize: '21px',
        [p.breakpoints.down('md')]: {
            fontSize: '21px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '17px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '15px'
        },
    },
    store: {
        paddingTop: p.spacing(5),
        paddingBottom: p.spacing(3),
    },
    playStore: {
        paddingLeft: p.spacing(3),
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(3),
        },
        cursor: 'pointer',
    },
    videoHolder: {
        width: '100%',
        // border: '1px solid #254CFF',
        padding: p.spacing(3),
        [p.breakpoints.down('md')]: {
            padding: '0px',
        },
        [p.breakpoints.down('sm')]: {
            padding: '0px',
        },
        [p.breakpoints.down('xs')]: {
            padding: '0px',
        },
    },
    imageDropVideo: {
        width: '100%',
        height: '420px',
        border: '1px solid #254CFF',
        padding: p.spacing(1),

        // [p.breakpoints.down('md')]: {
        //     width: '1024px',
        //     height: '768px',
        // },
        // [p.breakpoints.down('sm')]: {
        //     width: '768px',
        //     height: '480px',
        // },
        [p.breakpoints.down('xs')]: {
            width: '100%',
            height: '230px',
        },
    },
    describeVideo: {
        color: '#4B4B4B',
        fontSize: '18px',
        textAlign: 'right',
        paddingTop: p.spacing(3),
        fontFamily: 'Bebas Neue',
        [p.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    content: {
        padding: p.spacing(0,0),
    },
    textContent: {
        // paddingTop: '50px',
        paddingBottom: '20px',
        // paddingLeft: '10px',
        paddingRight: '10px',
    },
    radiusCover: {
        width: '100%',
        marginBottom: '-5px',
    }
}))

const beetel = {
    name: "Beetle",
    video1: "https://www.youtube.com/embed/NtN7ilX9BQA",
    video2: "",
    description: [
        "Shorter Delivery Time, Safer and Better Food, To-the-Door Delivery, Better User Experience",
        "- Member Registration",
        "- Order Confirmation",
        "- Fleet Optimization",
        "- Deep-tech Solutions",
        "- Feedback",
    ]
}

const healer = {
    name: "Healer",
    video1: "https://www.youtube.com/embed/MmqM8HiixUU",
    video2: "https://www.youtube.com/embed/BIjtKgamoUo",
    description: [
        "The epidemic is complicated. You need a remote treatment.",
        "can help you solve that quickly, conveniently and effectively.",
    ]
}

const HowItWorks = () => {

    const classes = useStyles()

    const [data, setData] = useState(beetel)

    useEffect(() => {
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)
        }, 1000);
    }, [])

    return (
        <div className={classes.root} id="how-it-works">
            <div className={classes.contentRoot}>
                <Typography className={classes.title} data-aos="fade-down">
                    How it works ?
                </Typography>
                <Grid container justify="space-around" className={classes.content}>
                    {(data.name==='Beetle') && <Grid item lg={6} className={classes.textContent} data-aos="fade-up-left">
                        {data.description.map((item, i) => (
                            <Typography className={classes.decribe} key={i}>
                                {item}
                            </Typography>
                        ))}
                    </Grid>}
                    {(data.name==='Healer') && <Grid item lg={6} className={classes.textContent} data-aos="fade-up-left">
                        <Typography className={classes.decribe}>
                            {data.description[0]}
                        </Typography>
                        <Typography className={classes.decribe}>
                            <a href="#about" className={classes.strongBlue}> Healer Bot </a>
                            {data.description[1]}
                        </Typography>
                    </Grid>}
                    <Grid item lg={6} className={classes.videoHolder}>
                        <iframe data-aos="fade-up" src={data.video1} className={classes.imageDropVideo} title="MyFrame">
                            <p data-aos="fade-up">Your browser does not support iframe.</p>
                        </iframe>
                        <Typography className={classes.describeVideo}>
                            Watch this video to learn about the basic features of the bettle
                        </Typography>
                        {/* <video controls className={classes.imageDropVideo}>
                            <source src="/videos/intro.mp4" type="video/mp4"/>
                            Your browser does not support the video play.
                        </video> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default HowItWorks
