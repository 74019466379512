import React, {useState,useEffect} from 'react'
import { 
    Typography,
    Grid,
    Button,
    ButtonGroup 
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TYPE_ROBOT, changeTypeRobot } from '../../../configs'


const useStyles = makeStyles(p => ({
    root: {
        backgroundColor: '#FFFFFF',
        backgroundImage: 'url(/images/futureWorksBG.png)',
        backgroundSize: 'cover',
        position: 'relative',
        // textAlign: 'center',
        padding: p.spacing(10,0)
    },
    contentRoot: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        // paddingTop: p.spacing(10),
        color: '#3A5BF5',
        fontSize: '48px',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        [p.breakpoints.down('md')]: {
            fontSize: '44px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px'
        },
    },
    content: {
        padding: p.spacing(7,3),
        justifyContent: 'normal',
        [p.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    contentLeft: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        margin: 'auto 0',
        [p.breakpoints.down('sm')]: {
            position: 'static'
        },
    },
    contentLeftHideMobile: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        margin: 'auto 0',
        [p.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    contentLeftShowMobile: {
        position: 'relative',
        display: 'none',
        justifyContent: 'flex-end',
        textAlign: 'right',
        margin: 'auto 0',
        [p.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
    imageHardware: {
        // position: 'absolute',
        // top: 0,
        // bottom: 0,
        // left: 0,
        // right: 0,
        // margin: 'auto',
        [p.breakpoints.down('md')]: {
            position: 'static',
            width: 380
        },
    },
    contentRight: {
        padding: p.spacing(5,2),
        textAlign: 'left',
        margin: 'auto 0'
    },
    itemContentRight: {
        marginBottom: p.spacing(3),
        paddingBottom: p.spacing(3),
        width: 400,
        [p.breakpoints.down('md')]: {
            padding: p.spacing(3)
        },
    },
    titleTextRight: {
        color: '#254CFF',
        fontSize: '20px',
        fontWeight: 'bold',
        // width: '500px',
        textTransform: 'uppercase',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '18px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '16px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '14px',
            width: '350px',
        },
    },
    titleTextLeft: {
        color: '#254CFF',
        fontSize: '20px',
        fontWeight: 'bold',
        // width: '500px',
        textTransform: 'uppercase',
        textAlign: 'right',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '18px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '16px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '14px',
            width: '350px',
        },
    },
    contentText: {
        color: '#B3B3B3',
        fontSize: '20px',
        width: '500px',
        textAlign: 'left',
        display: 'inline',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '18px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '16px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '14px',
            width: '350px',
        },
    },
    buttonGroup: {
        display: 'flex',
        [p.breakpoints.down('xs')]: {
            display: 'none'
        },
        [p.breakpoints.down('md')]: {
            display: 'none'
        },
        [p.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    buttonGroupMobile: {
        display: 'none',
        marginTop: p.spacing(2),
        [p.breakpoints.down('xs')]: {
            display: 'flex'
        },
        [p.breakpoints.down('md')]: {
            display: 'flex'
        },
        [p.breakpoints.down('sm')]: {
            display: 'flex'
        },
    },
    contentButtonGroup: {
        transform: 'rotate(90deg)',
        [p.breakpoints.down('xs')]: {
            margin: 'auto',
            transform: 'none',
        },
        [p.breakpoints.down('md')]: {
            margin: 'auto',
            transform: 'none',
        },
        [p.breakpoints.down('sm')]: {
            margin: 'auto',
            transform: 'none',
        },
    },
    switchButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#ABABAB',
        height: 'fit-content',
        margin:' auto'
    },
    switchSelectedButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#254CFF',
        height: 'fit-content',
        margin:' auto'
    },
    imgIconButton: {
        marginLeft: p.spacing(2),
        marginBottom: p.spacing(1)
    },
    imageDropVideo: {
        width: '100%',
        height: '420px',
        padding: p.spacing(1),
        // [p.breakpoints.down('md')]: {
        //     width: '1024px',
        //     height: '768px',
        // },
        // [p.breakpoints.down('sm')]: {
        //     width: '768px',
        //     height: '480px',
        // },
        [p.breakpoints.down('xs')]: {
            width: '100%',
            height: '230px',
        },
    }
}))

const beetel = {
    name: "BeetleBot",
    title1: "REMOVABLE CARGO CONTAINER ",
    description1: "The container can be removable and redesigned based on the needs and environment. Or it will be integrated a cooling system for food preservation.",
    title2: "ROBOTIC ARM",
    description2: "Robotic Arm for lifting and picking up object. Robot arm can be used to replace the need for traditional lift and transfer system, especially in high temperature, poison, disease environment, or quarantine area.",
    title3: "Rescure drone",
    description3: "Used to spray disinfectants from above to cover the large life. Observe and rescue people in danger in difficult terrain.",
    image1: "images/futureworks/removableRobo.png",
    image2: "images/futureworks/armRobo.png",
    image3: "images/futureworks/drone.png"
}

const healer = {
    name: "HealerBot",
    title1: "Disinfection function ",
    description1: "The robot is equipped with an automatic UV sterilizer. Used to disinfect hospitals, schools, offices, .... In a quick, efficient and economical manner.",
    title2: "Friendly human interaction",
    description2: "Nurse robot assists doctors in taking care of patients, transporting medicines to isolated areas. Limit exposure in epidemics.",
    title3: "Food delivery",
    description3: "Transport goods, food, water to different areas, save energy and human resources.",
    image1: "images/futureworks/healer.png",
    image2: "images/futureworks/healer-front.png",
    image3: "images/futureworks/healer-front.png"
}

const FutureWorks = () => {

    const classes = useStyles()

    const [data, setData] = useState(beetel)

    useEffect(() => {
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)

        }, 1000);
    }, [])

    const handleChangeTypeRobot = () => {
        changeTypeRobot();
    }

    return (
        <div id="future-works" className={classes.root}>
            <div className={classes.contentRoot}>
                <Typography className={classes.title}>
                    Future Works
                    <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />
                </Typography>
                <div lg={2} className={classes.buttonGroupMobile}>
                            <ButtonGroup
                                 aria-label="vertical outlined primary button group"
                                 className={classes.contentButtonGroup}
                             >
                            <Button
                                onClick={handleChangeTypeRobot}
                                className={data.name=== "BeetleBot"?classes.switchSelectedButton:classes.switchButton}
                            >
                                BettleBot
                            </Button>
                            <Button
                                onClick={handleChangeTypeRobot}
                                className={data.name=== "HealerBot"?classes.switchSelectedButton:classes.switchButton}
                            >
                                HealerBot
                            </Button>
                            </ButtonGroup>
                </div>
                <Grid container justify="space-around" className={classes.content} spacing={10}>
                        <Grid item lg={5} md={6} className={classes.contentLeft}>
                            <img src={data.image1} alt="robot" className={classes.imageHardware} data-aos="zoom-out"/>
                        </Grid>
                        <Grid item lg={5} md={6} className={classes.contentRight}>
                            <div className={classes.itemContentRight}  data-aos="zoom-in">
                                <Typography className={classes.titleTextRight}>{data.title1}</Typography>
                                <Typography className={classes.contentText}>{data.description1}</Typography>
                            </div>
                        </Grid>
                        <Grid item lg={2} className={classes.buttonGroup}>
                            <ButtonGroup
                                 aria-label="vertical outlined primary button group"
                                 className={classes.contentButtonGroup}
                             >
                            <Button
                                onClick={handleChangeTypeRobot}
                                className={data.name=== "BeetleBot"?classes.switchSelectedButton:classes.switchButton}
                            >
                                BettleBot
                            </Button>
                            <Button
                                onClick={handleChangeTypeRobot}
                                className={data.name=== "HealerBot"?classes.switchSelectedButton:classes.switchButton}
                            >
                                HealerBot
                            </Button>
                            </ButtonGroup>
                        </Grid>
                       
                        <Grid item lg={5} md={6} className={classes.contentLeftHideMobile}>
                            <div className={classes.itemContentRight}  data-aos="zoom-in">
                                <Typography className={classes.titleTextLeft}>{data.title2}</Typography>
                                <Typography className={classes.contentText}>{data.description2}</Typography>
                            </div>
                        </Grid>
                        <Grid item xl={4} lg={5} md={6} className={classes.contentRight}>
                            <img src={data.image2} alt="robot" className={classes.imageHardware} data-aos="zoom-out"/>
                        </Grid>
                        <Grid item lg={5} md={6} className={classes.contentLeftShowMobile}>
                            <div className={classes.itemContentRight}  data-aos="zoom-in">
                                <Typography className={classes.titleTextLeft}>{data.title2}</Typography>
                                <Typography className={classes.contentText}>{data.description2}</Typography>
                            </div>
                        </Grid>

                        <Grid item lg={5} md={6} className={classes.contentLeft}>
                            <video controls className={classes.imageDropVideo}>
                                <source src="/videos/robot_animation.mp4" type="video/mp4"/>
                                Your browser does not support the video play.
                            </video>
                        </Grid>
                        <Grid item lg={5} md={6} className={classes.contentRight}>
                            <div className={classes.itemContentRight}  data-aos="zoom-in">
                                <Typography className={classes.titleTextRight}>{data.title3}</Typography>
                                <Typography className={classes.contentText}>{data.description3}</Typography>
                            </div>
                        </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default FutureWorks