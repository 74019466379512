import React, {useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'
import { TYPE_ROBOT } from '../../../configs'


const useStyles = makeStyles(p => ({
    root: {
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    titleContainer: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        color: '#3A5BF5',
        fontSize: '48px',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        paddingTop: p.spacing(8),
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(10),
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    questionIcon: {
        color: '#254CFF',
        fontSize: '48px',
        [p.breakpoints.down('md')]: {
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    decribe: {
        color: '#000000',
        fontSize: '24px',
        textAlign: 'left',
        fontFamily: 'PT Sans',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '22px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    strongBlue: {
        color: '#254CFF',
        fontWeight: 'bold',
        fontSize: '21px',
        [p.breakpoints.down('md')]: {
            fontSize: '21px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '17px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '15px'
        },
    },
    imageDropVideo: {
        width: '720px',
        height: '420px',
        border: '0px',
        [p.breakpoints.down('md')]: {
            width: '1024px',
            height: '768px',
        },
        [p.breakpoints.down('sm')]: {
            width: '768px',
            height: '480px',
        },
        [p.breakpoints.down('xs')]: {
            width: '100%',
            height: '230px',
        },
    },
    content: {
        paddingTop: '20px',
    },
    textContent: {
        paddingTop: '50px',
        paddingBottom: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    aboutIcon: {
        color: '#234AFF',
    },
    contentAdventure: {
        marginTop: p.spacing(10)        
    },
    titleDetail: {
        color: '#F2F2F2',
        fontSize: '24px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        marginTop: p.spacing(1)
    },
    detailItem: {
        padding: p.spacing(10,4),
        background: '#3A5BF5',
        borderRadius: '28px',
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.25)',
        width: '330px',
        minHeight: '400px',
        textAlign: 'center'
    },
    description: {
        color: '#F2F2F2',
        fontSize: '14px',
    },
    imgIconButton: {
        marginLeft: p.spacing(2),
        marginBottom: p.spacing(1)
    },
    itemGrid: {
        [p.breakpoints.down('md')]: {
            margin: p.spacing(1)
        },
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(4)
        }
    },
}))

const beetel = {
    name: "BeetleBot",
    description1: "User benefits:",
    description2: "- Reduce Waiting Time ",
    description3: "- Take your Order at your Door",
    description4: "- Reduce Cost",
    description5: "- Improve Safe and Clean",
    listDetail: [
        {
            title: 'Self-Driving Delivery Robot',
            image: 'images/icons/selfDriving.png',
            description: [
                "Carries items within a 6km radius",
                "Request via mobile app",
                "Directly delivery from stores",
                "Monitor robot’s journey on smartphone",
            ]

        },
        {
            title: 'Saving Everywhere',
            image: 'images/icons/savingEnergy.png',
            description: [
                "Outdoor and indoor robot",
                "Well works in disease or poison environment",
            ]
        },
        {
            title: 'Safe and Secure',
            image: 'images/icons/safeAndSecure.png',
            description: [
                "The cargo bay is mechanically locked",
                "The location of the robots is tracked",
                "Robots move at pedestrian speed",
                "Can navigate around objects and people",
            ]
        }
    ]
}

const healer = {
    name: "HealerBot",
    description1: "- CONVENIENCE",
    description2: "- DEEP-TECH",
    description3: "- SMART and AUTONOMOUS",
    description4: "Our Vision",
    description5: "The pioneer & leader of cutting-edge AI and Robotics technologies not only in Vietnam, but also across ASEAN countries.",
    listDetail: [
        {
            title: 'OBJECTIVE',
            image: 'images/icons/objective.png',
            description: [
                "Safe and Clean",
                "Low Cost",
                "On-Demand Delivery",
            ]
        },
        {
            title: 'DEEP-TECH',
            image: 'images/icons/deepTech.png',
            description: [
                "Fast and Secure",
                "Prepaid, Cashless",
                "AI meets Robotic",
                "Autonomous",
            ]
        },
        {
            title: 'CUSTOMER ORIENTED',
            image: 'images/icons/customOrient.png',
            description: [
                "User Experience Focus",
                "User Friendly",
                "Smart and Fun",
            ]
        }
    ]
}

const ChooseYourAdventure = () => {

    const classes = useStyles()

    const [data, setData] = useState(beetel)

    useEffect(() => {
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)

        }, 1000);
    }, [])

    return (
        <div className={classes.root} id="choose-your-adventure">
            <div className={classes.titleContainer}>
                <Typography className={classes.title} data-aos="fade-down">
                    Choose Your Adventure:
                    <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />
                </Typography>
                <Typography className={classes.decribe} data-aos="fade-up-left">
                    {data.description1}
                </Typography>
                <Typography className={classes.decribe} data-aos="fade-up-left">
                    {data.description2}
                </Typography>
                <Typography className={classes.decribe} data-aos="fade-up-left">
                    {data.description3}
                </Typography>
                <Typography className={classes.decribe} data-aos="fade-up-left">
                    {data.description4}
                </Typography>
                <Typography className={classes.decribe} data-aos="fade-up-left">
                    {data.description5}
                </Typography>
                <Grid className={classes.contentAdventure} justify="center" container>
                    {data.listDetail.map((item, i) => (
                        <Grid item lg={4} md={6} sm={6} xs={12} data-aos="fade-up" key={item.title} className={classes.itemGrid}>
                            <div className={classes.detailItem}>
                                <img src={item.image} alt={item.title}/>
                                <Typography className={classes.titleDetail}>
                                    {item.title}
                                </Typography>
                                {/* <Typography className={classes.description}>
                                    {item.description}
                                </Typography> */}
                                {item.description.map((descript, i) => (
                                    <Typography className={classes.description} key={i}>
                                        {descript}
                                    </Typography>
                                ))}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default ChooseYourAdventure
