import React, { useState, forwardRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Toolbar } from '@material-ui/core'
import { Dialog, Slide, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { TYPE_ROBOT, changeTypeRobot } from '../../../configs'

const useStyles = makeStyles(p => ({
    root: {
        // width: '100%',
        position: 'relative',
        textAlign: 'left',
        // backgroundColor: '#17171D',
        // backgroundImage: 'url(/images/introductionBeetleBG.png)',
        // backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    openingBG: {
        visibility: 'hidden',
        // backgroundImage: 'url(/images/introductionBG.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
    },
    quoteContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'stretch',
        top: '25%',
        left: '10%',
        right: '10%',
        [p.breakpoints.down('md')]: {
            top: '15%',
            left: '5%',
        },
        [p.breakpoints.down('sm')]: {
            top: '15%',
            left: '5%',
        },
        [p.breakpoints.down('xs')]: {
            top: '15%',
            left: '5%',
        },
    },
    quote: {
        color: '#F2F2F2',
        fontSize: '60px',
        flexGrow: 8,
        marginTop: p.spacing(3),
        marginBottom: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '48px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '36px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '20px',
        },
    },
    strongWhite: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '129px',
        textTransform: 'uppercase',
        [p.breakpoints.down('md')]: {
            fontSize: '117px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '102px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '40px',
        },
    },
    mainQuote: {
        marginBottom: p.spacing(1),
    },
    subQuote: {
        width: '500px',
        color: '#F2F2F2',
        fontSize: '18px',
        marginLeft: p.spacing(1),
        [p.breakpoints.down('md')]: {
            fontSize: '18px',
            width: '370px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '16px',
            width: '370px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '12px',
            width: '270px',
        },
    },
    aboutContainer: {
        // backgroundColor: '#17171D',
        // width: '100%',
        // height: '100%',
        top: '65%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        // textAlign: 'center',
        left: '10%',
        right: '10%',
        [p.breakpoints.down('md')]: {
            left: '5%',
        },
        [p.breakpoints.down('sm')]: {
            left: '5%',
        },
        [p.breakpoints.down('xs')]: {
            left: '0%',
        },
    },
    detail: {
        marginTop: p.spacing(2),
        marginBottom: p.spacing(2),
    },
    aboutIcon: {
        color: '#234AFF',
        [p.breakpoints.down('xs')]: {
            height: 50
        },
    },
    arrowIcon: {
        width: 25,
        height: 30,
        marginTop: p.spacing(12),
        [p.breakpoints.down('xs')]: {
            width: 15,
            height: 20,
        },
    },
    aboutTitle: {
        color: '#234AFF',
        fontSize: '18px',
        [p.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    aboutSubTitle: {
        color: '#F2F2F2',
        fontSize: '18px',
        margin: '0 auto',
        width: '400px',
        padding: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '16px',
            width: '350px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '300px',
        },
        [p.breakpoints.down('xs')]: {
            fontSizonge: '12px',
            width: '250px',
        },
    },
    playButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '80px',
        cursor: 'pointer',
        height: '80px',
        borderRadius: '50%',
        zIndex: 999,
        [p.breakpoints.down('md')]: {
            top: '40%',
            left: '50%',
        },
        [p.breakpoints.down('sm')]: {
            top: '40%',
            left: '50%',
        },
        [p.breakpoints.down('xs')]: {
            top: '40%',
            left: '60%',
            width: '50px',
        },
    },
    dialog: {
        backgroundColor: 'black',
        zIndex: 99999,
    },
    titleContact: {
        // paddingTop: p.spacing(10),
        marginLeft: p.spacing(1),
    },
    strongBlue: {
        color: '#254CFF',
        fontSize: '40px',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        paddingTop: p.spacing(15),
        marginLeft: p.spacing(1),
        borderBottom: '1px solid #254CFF',
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(10),
            fontSize: '34px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '28px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    functions: {
        display: 'flex',
        margin: p.spacing(5,2),
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(1,2),
        },
    },
    itemRight: {
        marginLeft: p.spacing(10),
        [p.breakpoints.down('xs')]: {
            marginLeft: p.spacing(1),
        },
    },
    textTopFunction: {
        color: '#FFFFFF',
        fontSize: 22,
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        lineHeight: '30px',
        [p.breakpoints.down('xs')]: {
            fontSize: 13,
        },
    },
    textBottomFunction: {
        color: '#FFFFFF',
        fontSize: 20,
        fontFamily: 'Bebas Neue',
        fontWeight: 300,
        [p.breakpoints.down('xs')]: {
            fontSize: 13,
        },
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const beetel = {
    name: "BeetleBot",
    description: "Last-mile delivery robot",
    description2: "Creative solution for healthcare",
    titleFunction1: "Smart shipper",
    textFunction1: "food and gift",
    titleFunction2: "auto direction",
    textFunction2: "to delivery",
    titleFunction3: "",
    textFunction3: "",
    image: 'url(/images/introductionBeetleBG.png)',
    video: "/videos/opening.mp4"
}

const healer = {
    name: "HealerBot",
    description: "Last-mile delivery robot",
    description2: "Creative solution for healthcare",
    titleFunction1: "Disinfection",
    textFunction1: "Hospital",
    titleFunction2: "Auto dodge obstacles",
    textFunction2: "To delivery",
    titleFunction3: "Connect people",
    textFunction3: "Quarantine by covid-19",
    image: 'url(/images/introductionHealerBG.png)',
    video: "/videos/opening.mp4"
}

const Introductions = () => {

    const classes = useStyles()

    const [isOpen, setOpen] = useState(false)

    const [data, setData] = useState(beetel)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        setTimeout(function(){
            document.getElementById("about").style.backgroundImage = '';
            document.getElementById("videoBG").style.visibility = "visible";
            document.getElementById("videoBG").play();
        }, 4000);
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)

        }, 1000);
    }, [])

    const handleChangeRobot = () => {
        changeTypeRobot();
        setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)
        
        document.getElementById("videoBG").style.visibility = "hidden";
        setTimeout(function(){
            document.getElementById("about").style.backgroundImage = '';
            document.getElementById("videoBG").style.visibility = "visible";
            document.getElementById("videoBG").play();
        }, 4000);
    }
    
    return (
        <div className={classes.root} id="about" style={{backgroundImage: data.image}}>
            <video id="videoBG" className={classes.openingBG} loop muted>
                <source src="/videos/opening.mp4" type="video/mp4"/>
                Your browser does not support the video play.
            </video>
            <div className={classes.playButton} onClick={handleOpen}>
                <lottie-player style={{width: '100%',}} speed="3" autoplay loop mode="normal" src="/play_button.json">
                </lottie-player>
                {/* <img src="/images/icons/play_button.png" alt="" style={{width: '100%', cursor: 'pointer',}}/> */}
            </div>
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}  classes={{paper: classes.dialog}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{color: '#F2F2F2'}}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <video controls autoPlay={true} loop>
                    <source src="/videos/intro.mp4" type="video/mp4"/>
                    Your browser does not support the video play.
                </video>
            </Dialog>
            <div className={classes.quoteContainer}>
                <img 
                    src="/images/icons/previousArrow.png"
                    className={classes.arrowIcon}
                    alt="preArrow"
                    style={{ marginLeft: '-20px'}}
                    onClick={handleChangeRobot}
                />
                <div className={classes.quote} data-aos="slide-up">
                    <Typography className={classes.mainQuote}>
                        <span className={classes.strongWhite}> {data.name} </span>
                    </Typography>
                    <Typography className={classes.subQuote}>
                        Last-mile delivery robot
                    </Typography>
                    <Typography className={classes.subQuote}>
                        Creative solution for healthcare
                    </Typography>
                </div>
                <img 
                    src="/images/icons/nextArrow.png"
                    className={classes.arrowIcon}
                    alt="nextArrow"
                    onClick={handleChangeRobot}
                />
            </div>
            <div className={classes.aboutContainer}>
                <div>
                <Typography className={classes.titleContact} data-aos="fade-up">
                    <a href="#contact" className={classes.strongBlue}> Contact Us</a>
                </Typography>
                <div className={classes.functions} data-aos="fade-up">
                    <div>
                        <Typography className={classes.textTopFunction}>
                            {data.titleFunction1}
                        </Typography>
                        <Typography className={classes.textBottomFunction}>
                            {data.textFunction1}
                        </Typography>
                    </div>
                    <div className={classes.itemRight}>
                        <Typography className={classes.textTopFunction}>
                            {data.titleFunction2}
                        </Typography>
                        <Typography className={classes.textBottomFunction}>
                            {data.textFunction2}
                        </Typography>
                    </div>
                    <div className={classes.itemRight}>
                        <Typography className={classes.textTopFunction}>
                            {data.titleFunction3}
                        </Typography>
                        <Typography className={classes.textBottomFunction}>
                            {data.textFunction3}
                        </Typography>
                    </div>                       
                </div>
                </div>
                {
                    (data.name === 'BeetleBot') && <img src="images/hackcovy_top1.png" className={classes.aboutIcon} alt="AI"/>
                }
            </div>
        </div>
    )
}
export default Introductions