import React from 'react'
import {
    Typography,
    Grid,
    Link
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// import { compose, withProps } from "recompose"
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import EmailForm from './EmailForm.js'

const useStyles = makeStyles(p => ({
    root: {
        backgroundColor: '#17171D',
        textAlign: 'center',
        backgroundImage: 'url(/images/contactBG.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: p.spacing(10,0,0,0),
        [p.breakpoints.down('xs')]: {
            padding: p.spacing(0),
        },
    },
    contentRoot: {
        margin: p.spacing(5,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        color: '#3A5BF5',
        fontSize: '48px',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        paddingTop: p.spacing(8),
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(10),
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    content: {
        padding: p.spacing(7,3),
        [p.breakpoints.down('xs')]: {
            padding: p.spacing(4,3),
        },
    },
    contentLeft: {
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    contentLeftMobile: {
        textAlign: 'left',
        display: 'none',
        [p.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    contentRight: {
        textAlign: 'left',
        paddingBottom: p.spacing(5),
    },
    itemContentRight: {
        marginBottom: p.spacing(3),
        marginLeft: p.spacing(5),
        [p.breakpoints.down('xs')]: {
            marginLeft: p.spacing(0),
        },
    },
    titleText: {
        color: '#254CFF',
        fontSize: '24px',
        fontWeight: 'bold',
        width: '500px',
        textAlign: 'left',
        fontFamily: 'Bebas Neue',
        paddingTop: p.spacing(3),
        marginLeft: p.spacing(1),
        [p.breakpoints.down('md')]: {
            fontSize: '22px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '20px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '18px',
            width: '350px',
        },
    },
    contentText: {
        color: '#FFFFFF',
        fontFamily: 'Bebas Neue',
        fontWeight: '300',
        fontSize: '24px',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        marginLeft: p.spacing(1),
        width: '100%',
        [p.breakpoints.down('md')]: {
            fontSize: '22px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '20px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '18px',
            width: '350px',
        },
    },
    imageDropVideo: {
        marginTop: p.spacing(3),
        border: '0px',
        width:640,
        height:360
    },
    footer: {
        padding: p.spacing(2,20),
        height: 70,
        backgroundColor: '#254CFF',
        display: 'flex',
        justifyContent: 'space-between',
        [p.breakpoints.down('xs')]: {
            padding: p.spacing(0,2),
            height: 60,
        },
    },
    textLeftFooter: {
        margin: "auto 0px",
        color: '#FFFFFF',
        fontFamily: 'Bebas Neue',
        fontWeight: 'normal',
        fontSize: '24px',
    },
    textRightFooter: {
        margin: "auto 0px",
        color: '#FFFFFF',
        fontFamily: 'Bebas Neue',
        fontWeight: 'normal',
        fontSize: '24px',
    },
    imgTwoRobot: {
        [p.breakpoints.down('xs')]: {
            width: 300
        },
    }
}))

const ContactUs = () => {

    const classes = useStyles()

    return (
        <div id="contact" className={classes.root}>
            <div className={classes.contentRoot}>
                {/* <Typography className={classes.title}>
                    Contact Us
                </Typography> */}
                <Grid container justify="space-around" className={classes.content}>
                    <Grid item lg={6} md={12} sm={12} className={classes.contentLeft}>
                        <Typography className={classes.title}>
                            Contact Us
                        </Typography>
                        <img src="images/twoRobot.png" alt="twoRobot" className={classes.imgTwoRobot} />
                        {/* <EmailForm/> */}
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} className={classes.contentRight} data-aos="fade-up-left">
                        <div className={classes.itemContentRight}>
                            <EmailForm/>
                            <Typography className={classes.titleText}>AIOZ</Typography>
                            <Typography className={classes.contentText}>AI Office</Typography>
                            <Typography className={classes.contentText}>ai.admin@aioz.io</Typography>
                            {/* <MyMapComponent/> */}
                            {/* <iframe src="https://www.youtube.com/embed/uSyCCHcJsvo" width="640" height="480" className={classes.imageDropVideo} title="MyFrame">
                                <p>Your browser does not support iframe.</p>
                            </iframe> */}
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} className={classes.contentLeftMobile}>
                        <img src="images/twoRobot.png" alt="twoRobot" className={classes.imgTwoRobot} />
                        {/* <EmailForm/> */}
                    </Grid>
                </Grid>
            </div>
            <div className={classes.footer}>
                <Typography className={classes.textLeftFooter}>
                    <Link
                        component="a"
                        href="https://aioz.io/"
                        target="_blank"
                        className={classes.textLeftFooter}
                    >
                        Copyright © AIOZ 
                    </Link>

                </Typography>
                <Typography variant="caption" className={classes.textRightFooter}>
                    2023
                </Typography>
            </div>

        </div>
    )
}

export default ContactUs
