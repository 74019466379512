import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Introductions, HowItWorks, Features, Hardware, FutureWorks, ContactUs, ChooseYourAdventure, OurTeam, Reference } from './components'
import AOS from 'aos'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    title: {
        color: 'blue',
        fontWeight: 300,
        textAlign: 'center'
    },
}))

const Home = () => {

    useEffect(() => {
        AOS.init({
            // disable: true,
            easing: 'ease-out-back',
            anchorPlacement: 'top-bottom',
            duration: 1000,
            once: false,
        })
    }, [])

    useEffect(() => {
        AOS.refresh({
            easing: 'ease-out-back',
            anchorPlacement: 'top-bottom',
            duration: 1000,
            once: false,
            mirror: true,
        })
    })

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Introductions />
            <ChooseYourAdventure />
            <HowItWorks />
            <Features />
            <Hardware />
            <FutureWorks />
            <Reference />
            <ContactUs />
        </div>
    )
}

Home.propTypes = {
    
}

export default withRouter(Home)
