import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Link } from '@material-ui/core'

const useStyles = makeStyles(p => ({
    root: {
        // backgroundColor: '#FFFFFF',
        textAlign: 'center',
        // width: '100%',
        // height: '100%',
        position: 'relative',
        backgroundImage: 'url(/images/referenceBG.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    titleContainer: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        color: '#3A5BF5',
        fontSize: '48px',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        paddingTop: p.spacing(8),
        [p.breakpoints.down('md')]: {
            paddingTop: p.spacing(10),
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    questionIcon: {
        color: '#254CFF',
        fontSize: '48px',
        [p.breakpoints.down('md')]: {
            fontSize: '44px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    },
    decribe: {
        color: '#000000',
        fontSize: '24px',
        textAlign: 'left',
        fontFamily: 'PT Sans',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '22px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    strongBlue: {
        color: '#254CFF',
        fontWeight: 'bold',
        fontSize: '21px',
        [p.breakpoints.down('md')]: {
            fontSize: '21px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '17px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '15px'
        },
    },
    imageDropVideo: {
        width: '720px',
        height: '420px',
        border: '0px',
        [p.breakpoints.down('md')]: {
            width: '1024px',
            height: '768px',
        },
        [p.breakpoints.down('sm')]: {
            width: '768px',
            height: '480px',
        },
        [p.breakpoints.down('xs')]: {
            width: '100%',
            height: '230px',
        },
    },
    content: {
        paddingTop: '20px',
    },
    textContent: {
        paddingTop: '50px',
        paddingBottom: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    aboutIcon: {
        color: '#234AFF',
    },
    contentAdventure: {
        marginTop: p.spacing(10)        
    },
    itemGrid: {
        '&:nth-child(even)': {
            margin: '80px auto'
        }
    },
    detailItem: {
        padding: p.spacing(10,4),
        margin: "0 auto",
        border: '2px solid #254CFF',
        borderRadius: '50px',
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.25)',
        width: '400px',
        minHeight: '380px',
        position: 'relative',
        textAlign: 'left',
        '&:nth-child(even)': {
            margin: '20px auto'
        },
        [p.breakpoints.down('xs')]: {
            width: 'auto'
        },
        // '&:before': {
        //     content: "",
        //     position: 'absolute',
        //     top: '-1px',
        //     right: '-1px',
        //     transform:'translate(50%,-50%)',
        //     width: '40px',
        //     height: '40px',
        //     borderRadius: '50%',
        //     background: '#4da6ff',
        //     mixBlendMode:'darken'
        // }
    },
    titleDetail: {
        color: '#254CFF',
        fontSize: '36px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        marginTop: p.spacing(3)
    },
    description: {
        marginTop: p.spacing(2),
        color: '#3F3F3F',
        fontFamily: 'PT Sans',
        fontSize: '21px',
    },
    twoDots: {
        position: 'absolute',
        top: '-5%',
        left: '-2%',
        width: 90,
        backgroundColor: '#FFFFFF',
        padding: p.spacing(1)
    },
    imgNews: {
        width: 320,
        border: '1px solid #3A5BF5',
        borderRadius: '10px',
        marginBottom: p.spacing(2),
        [p.breakpoints.down('xs')]: {
            width: 270
        },
    },
    logoSponsor: {
        // margin: p.spacing(0,20),
        width: '80%'
    },
    imgIconButton: {
        marginLeft: p.spacing(2),
        marginBottom: p.spacing(1)
    }
}))

const listNews = [
    {
        id: 1,
        title: 'United Nations Development Programme',
        link: 'https://www.vn.undp.org/content/vietnam/en/home/blog/RoboticApplication.html',
        image: 'images/reference/undp.png',
        description: 'Robotic application - the next frontier in treating infectious diseases?',
    },
    {
        id: 2,
        title: 'Thanh Nien News',
        link: 'https://thanhnien.vn/gioi-tre/giai-phap-quan-tri-khu-cach-ly-thong-minh-da-doat-giai-cuoc-thi-hack-co-vy-1217595.html',
        image: 'images/thunbnailNews.png',
        description: '"Trong phần thi tăng tốc dành cho các đội đã có sản phẩm khả dụng tối thiểu, giải nhất đã thuộc về đội AIOZ với dự án BettleBot, chế tạo ra người máy hỗ trợ y bác sĩ và bộ phận an ninh theo dõi, quản lý, giao tiếp từ xa, phát hiện những vi phạm về giãn cách xã hội và đeo khẩu trang.."',
    },
    {
        id: 3,
        title: 'Tuoi Tre Online',
        link: 'https://tuoitre.vn/ban-tre-o-tp-hcm-lam-robot-nhac-nho-nguoi-khong-deo-khau-trang-20200508083126867.htm',
        image: 'images/reference/tuoitre.png',
        description: 'Trong vòng 48 giờ, một nhóm bạn trẻ ở TP.HCM hoàn thiện các ứng dụng AI trên chú robot mang tên "Beetle Bot" giúp phát hiện người không đeo khẩu trang, người không thực hiện giãn cách an toàn.',
    },
    {
        id: 4,
        title: 'UK-RAS NETWORK',
        link: 'https://www.ukras.org/publications/ras-proceedings/UKRAS20/pp120-121',
        image: 'images/reference/ukras.png',
        description: 'We present BeetleBot, a new mobile robot that has been developed to operate in realistic environments. Differ- ent from previous state-of-the-art mobile robots, BeetleBot is designed based on the most recent advancements of actuator, mechanical design, and artificial intelligence',
    }
]

const Reference = () => {

    const classes = useStyles()

    return (
        <div className={classes.root} id="reference">
            <div className={classes.titleContainer}>
                <Typography className={classes.title} data-aos="fade-down">
                    Reference
                    <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />
                </Typography>
                <Grid className={classes.contentAdventure} justify="center" container spacing={8}>
                    {listNews.map((item, i) => (
                        <Grid item lg={5} md={5} sm={5} xs={12} data-aos="fade-up" key={item.id} className={classes.itemGrid}>
                            <div className={classes.detailItem}>
                                <img src="images/twoDots.png" alt="twoDots" className={classes.twoDots} />
                                <img src={item.image} alt={item.title} className={classes.imgNews} />
                                {/* <Typography className={classes.titleDetail}>
                                    {item.title}
                                </Typography> */}
                                <Link
                                    component="a"
                                    href={item.link}
                                    target="_blank"
                                    className={classes.titleDetail}
                                >
                                    {item.title}
                                </Link>
                                <Typography className={classes.description}>
                                    {item.description}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                
            </div>
            <img src="images/logoSponsor.png" alt="logoS" className={classes.logoSponsor} />
        </div>
    )
}

export default Reference
