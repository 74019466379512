import React, {useState,useEffect} from 'react'
import { Grid, Typography, Button, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TYPE_ROBOT, changeTypeRobot } from '../../../configs'

const useStyles = makeStyles(p => ({
    root: {
        // backgroundColor: '#FFFFFF',
        position: 'relative',
        backgroundImage: 'url(/images/featuresBG.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // textAlign: 'center',
        padding: p.spacing(10,0)
    },
    contentRoot: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        // paddingTop: p.spacing(10),
        // paddingBottom: p.spacing(5),
        color: '#3A5BF5',
        fontSize: '48px',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        marginBottom: p.spacing(2),
        [p.breakpoints.down('md')]: {
            fontSize: '44px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px'
        },
    },
    decribe: {
        color: '#000000',
        fontSize: '18px',
        fontFamily: 'PT Sans',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '17px',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '13px',
        },
    },
    strongBlue: {
        color: '#254CFF',
        fontWeight: 'bold',
        fontSize: '48px',
        [p.breakpoints.down('md')]: {
            fontSize: '44px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px'
        },
    },
    gallery: {
        marginTop: p.spacing(3)
    },
    galleryItem: {
        paddingBottom: p.spacing(3),
        width: '100%',
        [p.breakpoints.down('xs')]: {
            margin: '0 30px'
        },
    },
    detailGallery: {
        padding: p.spacing(2,2,4,2),
        background: '#3A5BF5',
        borderRadius: '28px',
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.25)',
        width: '270px',
        minHeight: '220px',
        textAlign: 'center'
    },
    imageTitle: {
        marginTop: p.spacing(2),
        color: '#F2F2F2',
        fontSize: '16px',
    },
    imageFeatures: {
        borderRadius: '20px',
        width: 240
    },
    contentDescription: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonGroup: {
        display: 'flex',
        [p.breakpoints.down('xs')]: {
            width: 100
        },
    },
    contentButtonGroup: {
        transform: 'rotate(90deg)'
    },
    switchButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#ABABAB',
        height: 'fit-content',
        margin:' auto'
    },
    switchSelectedButton: {
        textTransform: 'uppercase',
        border: 'transparent',
        color:'#254CFF',
        height: 'fit-content',
        margin:' auto'
    },
    imgIconButton: {
        marginLeft: p.spacing(2),
        marginBottom: p.spacing(1)
    }
}))

const beetel = {
    name: "BeetleBot",
    description1: "Let us show you why we’re a good option.",
    description2: "We are building a reliable, friendly, and efficient robot platform that",
    description3: "is allowing every person or business to offer a delivery service for a",
    description4: "very affordable price.",
    listFeatures: [
        {
            title: 'Self-Navigation',
            url: '/images/gif/auto_direction.gif',
        },
        {
            title: 'Rotate 360 Degree',
            url: '/images/gif/360.gif',
        },
        {
            title: 'Stairs Climbing',
            url: '/images/gif/climb.gif',
        },
        {
            title: 'Obstacle Avoiding',
            url: '/images/gif/obstacle.gif',
        },
        {
            title: 'Face Recognition',
            url: '/images/gif/face_regco.gif',
        },
        {
            title: 'Auto-lid Cargo',
            url: '/images/gif/auto_cargo.gif',
        },
        {
            title: 'Communication',
            url: '/images/gif/communication.gif',
        },
        {
            title: 'Smart Interaction',
            url: '/images/gif/smart_interaction.gif',
        },
    ]
}

const healer = {
    name: "HealerBot",
    description1: "We want BEST-CASE SCENARIO, REALISTIC SUCCESS SCENARIO, WORST-CASE SCENARIO:.",
    description2: "Become market leader of autonomous last-mile delivery in ASEAN.",
    description3: "Deploy solutions to university, company campus, hospitals, etc...Generates profit from building AIaaS (AI as a Service).",
    description4: "Deliver on-demand AI & Robotics solutions to local businesses.",
    listFeatures: [
        {
            title: 'Self-Navigation',
            url: '/images/gif/selfnavi.gif',
        },
        {
            title: 'Obstacle Avoidance',
            url: '/images/gif/osta.gif',
        },
        {
            title: 'Tele-Commuting',
            url: '/images/gif/tele.gif',
        },
        {
            title: 'Disinfection',
            url: '/images/gif/disi.gif',
        },
        {
            title: 'Patient Recognition',
            url: '/images/gif/recog.gif',
        },
        {
            title: 'Food-Drugs Delivery',
            url: '/images/gif/delivery.gif',
        },
    ]
}

const effects = [
    'fade-up',
    'fade-up',
    'slide-down',
    'slide-down',
    'fade-up-right',
    'fade-up-right',
    'slide-up',
    'slide-up',
]

const Features = () => {

    const classes = useStyles()

    // const urls = images.map(item => ({url: item.url, type: 'photo', altTag: item.title}))
    // const options = {data: urls, startIndex: 0, open: true}
    const handleOpen = i => {}

    const [data, setData] = useState(beetel)

    useEffect(() => {
        setInterval(() => {
            setData(TYPE_ROBOT === "BeetleBot" ? beetel : healer)

        }, 1000);
    }, [])

    const handleChangeTypeRobot = () => {
        changeTypeRobot();
    }
    
    return (
        <div className={classes.root} id="features">
            <div className={classes.contentRoot}>
                <Typography className={classes.title} data-aos="fade-down">
                    Features
                    <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />
                </Typography>
                <div className={classes.contentDescription}>
                    <div>
                        <Typography className={classes.decribe}>
                            {data.description1}
                        </Typography>
                        <Typography className={classes.decribe}>
                            {data.description2}
                        </Typography>
                        <Typography className={classes.decribe}>
                            {data.description3}
                        </Typography>
                        <Typography className={classes.decribe}>
                            {data.description4}
                        </Typography>
                    </div>
                    <div className={classes.buttonGroup}>
                        <ButtonGroup
                             aria-label="vertical outlined primary button group"
                             className={classes.contentButtonGroup}
                         >
                        <Button
                            onClick={handleChangeTypeRobot}
                            className={data.name=== "BeetleBot"?classes.switchSelectedButton:classes.switchButton}
                        >
                            BettleBot
                        </Button>
                        <Button
                            onClick={handleChangeTypeRobot}
                            className={data.name=== "HealerBot"?classes.switchSelectedButton:classes.switchButton}
                        >
                            HealerBot
                        </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Grid className={classes.gallery} alignContent="center" container>
                    {data.listFeatures.map((item, i) => (
                        <Grid item xl={3} lg={4} md={4} sm={6} className={classes.galleryItem} data-aos={effects[i]} key={item.title}>
                            <div className={classes.detailGallery}>
                                <img className={classes.imageFeatures} src={item.url} alt={item.title} onClick={() => handleOpen(i)}/>
                                <Typography className={classes.imageTitle}>
                                    {item.title}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}


export default Features