import React, {
    // useState,
    // useRef,
    // useImperativeHandle,
    forwardRef,
} from "react";
import { makeStyles } from "@material-ui/styles";
import Slider from "react-slick";
// import styles from "./styles.css";
import { Typography } from "@material-ui/core";
import styles from "./styles.scss";

const useStyles = makeStyles((p) => ({
    slider: {
        margin: "16px 16px",
        backgroundColor: 'black',
        maxHeight: '100px',
        display: 'inline',
        overflow: 'hidden'
    },
    root: {
        overflow: 'hidden',
        position: 'relative',
    },
    contentItem: {
        // padding: theme.spacing(0, 1),
        // backgroundColor: 'white',
        position: "relative",
        width: 212,
        borderRadius: "10px",
        // overflow: 'hidden',
    },
    itemImage: {
        height: 212,
        width: 212,
        margin: "0 auto",
        borderRadius: "20px",
    },
    // slickSlide: {
    //     padding: "0 15px",
    // },
    // none: {
    //     display: "none",
    // },
    nameMember: {
        marginTop: p.spacing(2),
        color: '#F2F2F2',
        fontSize: '21px',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    description: {
        marginTop: p.spacing(2),
        color: '#F2F2F2',
        fontSize: '16px',
        textAlign: 'center'
    },
}));

const SliderList = forwardRef((props, ref) => {
    const classes = useStyles();

    const { items } = props;

    // const refSlider = useRef({});

    // const [indexSlide, setIndexSlide] = useState(0);

    // useImperativeHandle(ref, () => {
    //     return {
    //         next: next,
    //         previous: previous,
    //         goto: goto
    //     };
    // });

    // const next = () => {
    //     refSlider.current.slickNext();
    // };

    // const previous = () => {
    //     refSlider.current.slickPrev();
    // };

    // const goto = (index) => {
    //     // console.log(index);
    //     refSlider.current.slickGoTo(index,true);
    // }

    const settings = {
        dots: false,
        slidesToScroll: 1,
        // slidesToShow: (items && (items.length >= 10 ? 10 : items.length)) || 2,
        slidesToShow: 5,
        arrows: true,
        infinite: true,
        lazyLoad: true,
        swipeToSlide: true,
        // cssEase: "linear",
        // centerMode: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${
        //             index + 1
        //         }, background: #222; color: #bada55`
        //     );
        //     // handleChangeSlide(index);
        // },
        responsive: [
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow:4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow:3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={classes.root}>
        <Slider {...settings} className={classes.slider}>
            {items.map((item) => {
                    if (item.id) {
                        return (
                            <div key={item.id}>
                                <div className={classes.contentItem}>
                                    <img
                                        src={
                                            item.image === ""
                                                ? "/images/avatar.png"
                                                : item.image
                                        }
                                        key={item.id}
                                        alt={item.title}
                                        className={classes.itemImage}
                                        // onClick={() => handleSelectItem(item)}
                                    />
                                    <Typography className={classes.nameMember}>
                                        {item.title}
                                    </Typography>
                                    <Typography className={classes.description}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
        </Slider>                   
        </div>
    );
});

export default SliderList;
