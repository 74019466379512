import React, { useState, useEffect } from "react";
import {
  Typography,
//   Grid,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((p) => ({
  root: {
    // backgroundColor: "#17171D",
  },
  title: {
    color: '#3A5BF5',
    fontSize: '48px',
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    paddingTop: p.spacing(8),
    marginBottom: p.spacing(4),
    [p.breakpoints.down('md')]: {
        paddingTop: p.spacing(10),
        fontSize: '44px',
    },
    [p.breakpoints.down('sm')]: {
        fontSize: '40px',
    },
    [p.breakpoints.down('xs')]: {
        fontSize: '36px',
    },
},
  content: {
    
  },
  contentLeft: {
    textAlign: "left",
  },
  contentRight: {
    paddingRight: p.spacing(1)
  },
  itemContentRight: {
    marginBottom: p.spacing(3),
    marginLeft: p.spacing(5),
  },
  titleText: {
    color: "#254CFF",
    fontSize: "24px",
    fontWeight: "bold",
    width: "500px",
    textAlign: "left",
    paddingTop: p.spacing(3),
    [p.breakpoints.down("md")]: {
      fontSize: "22px",
      width: "100%",
    },
    [p.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "100%",
    },
    [p.breakpoints.down("xs")]: {
      fontSize: "18px",
      width: "350px",
    },
  },
  contentText: {
    color: "#FFFFFF",
    fontSize: "24px",
    textAlign: "left",
    paddingTop: p.spacing(3),
    width: "100%",
    [p.breakpoints.down("md")]: {
      fontSize: "22px",
      width: "100%",
    },
    [p.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "100%",
    },
    [p.breakpoints.down("xs")]: {
      fontSize: "18px",
      width: "350px",
    },
  },
  titleMail: {
    fontSize: 24,
    fontWeight: 'bold',
    color: "#3A5BF5",
    fontFamily: 'Bebas Neue',
    textAlign: "left",
    marginBottom: p.spacing(1),
    textTransform: 'uppercase',
    marginLeft: p.spacing(1),
    [p.breakpoints.down("xs")]: {
       fontSize: 20
    },
  },
  inputEmail: {
    fontStyle: "normal",
    fontWeight: "bold",
    // backgroundColor: "#FFFFFF",
    color: '#FFFFFF',
    // paddingLeft: p.spacing(3),
    paddingRight: p.spacing(3),
    paddingTop: p.spacing(1),
    marginBottom: p.spacing(2),
    marginLeft: p.spacing(1),
    height: 40,
    fontSize: 16,
    '& .MuiInput-underline:before': {
        borderBottomColor: '#3A5BF5',
    },
  },
  input: {
    color: '#FFFFFF',
  },
  inputTitleEmail: {
    fontStyle: "normal",
    fontWeight: "bold",
    color: '#FFFFFF',
    // backgroundColor: "#FFFFFF",
    // paddingLeft: p.spacing(3),
    paddingRight: p.spacing(3),
    paddingTop: p.spacing(1),
    marginTop: p.spacing(1),
    marginBottom: p.spacing(2),
    marginLeft: p.spacing(1),
    height: 40,
    // maxWidth: 800,
    fontSize: 16,
    '& .MuiInput-underline:before': {
        borderBottomColor: '#3A5BF5',
    },
  },
  sendButton: {
    margin: p.spacing(1, 3, 1, 0),
    paddingLeft: p.spacing(1),
    backgroundColor: "transparent",
    color: '#3A5BF5',
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: "uppercase",
    lineHeight: '1em',
    height: 40,
  },
  labelButton: {
    borderBottom: '1px solid #3A5BF5'
  },
  imgIconButton: {
    marginLeft: '-20px',
    marginBottom: '4px',
    width: 20
}
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
      minimum: 6,
    },
  },
  title: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  message: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const EmailForm = () => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSend = () => {
    setTimeout(() => {
        setFormState(() => ({
            values: {},
            isValid: false,
            touched: {},
            errors: {},
        }));
    }, 1000);
    
  };

  return (
    <div className={classes.root} data-aos="fade-up">
        <Typography className={classes.title}>
            Contact Us
        </Typography>
      <Typography className={classes.titleMail}>Your Name</Typography>
      <TextField
        fullWidth
        name="name"
        InputProps={{
            className: classes.input
        }}
        className={classes.inputEmail}
        onChange={handleChange}
        value={formState.values.name || ""}
      />
      {hasError("name") && (
        <FormHelperText className={classes.helperText}>
          *{formState.errors.name[0]}
        </FormHelperText>
      )}
      <Typography className={classes.titleMail}>Email Title</Typography>
      {/* <Grid container className={classes.content}>
        <Grid item lg={9} md={9} sm={9} className={classes.contentLeft}> */}
          <TextField
            fullWidth
            name="title"
            InputProps={{
                className: classes.input
            }}
            className={classes.inputTitleEmail}
            onChange={handleChange}
            value={formState.values.title || ""}
          />
          {hasError("title") && (
            <FormHelperText className={classes.helperText}>
              *{formState.errors.title[0]}
            </FormHelperText>
          )}
        {/* </Grid>
        <Grid item lg={3} md={3} sm={3} className={classes.contentRight}>
          <Button
            fullWidth
            onClick={handleSend}
            className={classes.sendButton}
            color="primary"
            variant="contained"
            href={`mailto:ai.admin@aioz.io?subject=${formState.values.title + ' [ '+formState.values.name + ' ]'}&body=${formState.values.message}`}
          >
            Send
          </Button>
        </Grid>
      </Grid> */}
      <Typography className={classes.titleMail}>Your Message</Typography>
      <TextField
        fullWidth
        name="message"
        InputProps={{
            className: classes.input
        }}
        className={classes.inputEmail}
        onChange={handleChange}
        value={formState.values.message || ""}
      />
      {hasError("message") && (
        <FormHelperText className={classes.helperText}>
          *{formState.errors.message[0]}
        </FormHelperText>
      )}
      <Typography className={classes.titleMail}>Your Phone</Typography>
      <TextField
        fullWidth
        name="phone"
        InputProps={{
            className: classes.input
        }}
        className={classes.inputEmail}
        onChange={handleChange}
        value={formState.values.phone || ""}
      />
      {hasError("phone") && (
        <FormHelperText className={classes.helperText}>
          *{formState.errors.message[0]}
        </FormHelperText>
      )}
      <Button
            // fullWidth
            onClick={handleSend}
            className={classes.sendButton}
            classes={{ label: classes.labelButton}}
            // color="primary"
            // variant="contained"
            href={`mailto:ai.admin@aioz.io?subject=${formState.values.title + ' [ '+formState.values.name + ' ]'}&body=${formState.values.message + '%0D%0A %0D%0A My number phone : '+formState.values.phone}`}
          >
            Submit
          </Button>
          <img src={'/images/hardware/arrow-right.png'} alt="Error" className={classes.imgIconButton} />

    </div>
  );
};

export default EmailForm;
