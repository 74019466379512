import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import 'aos/dist/aos.css'
import validators from './common/validators'
import '@lottiefiles/lottie-player'
import Routes from './Routes'

const browserHistory = createBrowserHistory()

validate.validators = {
  ...validate.validators,
  ...validators
}


const App = () => { 
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  )
}

export default App