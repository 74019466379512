import React from 'react'
import {
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SliderList from './SliderList.js'


const useStyles = makeStyles(p => ({
    root: {
        backgroundImage: 'url(/images/ourTeamBG.png)',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundSize: 'cover',
        // textAlign: 'center',
        padding: p.spacing(22,0)
    },
    contentRoot: {
        margin: p.spacing(10,19),
        textAlign: 'left',
        [p.breakpoints.down('xs')]: {
            margin: p.spacing(3,5),
        },
    },
    title: {
        // paddingTop: p.spacing(10),
        color: '#3A5BF5',
        fontSize: '48px',
        fontFamily: 'Bebas Neue',
        fontWeight: 'bold',
        marginBottom: p.spacing(12),
        [p.breakpoints.down('md')]: {
            fontSize: '44px'
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '40px'
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '36px'
        },
    },
    content: {
        padding: p.spacing(7,3),
    },
    contentLeft: {
        position: 'relative',
        textAlign: 'right',
        margin: 'auto 0',
        [p.breakpoints.down('sm')]: {
            position: 'static'
        },
    },
    imageHardware: {
        // position: 'absolute',
        // top: 0,
        // bottom: 0,
        // left: 0,
        // right: 0,
        // margin: 'auto',
        [p.breakpoints.down('md')]: {
            position: 'static',
        },
    },
    contentRight: {
        padding: p.spacing(5,2),
        textAlign: 'left',
        margin: 'auto 0'
    },
    itemContentRight: {
        marginBottom: p.spacing(3),
        paddingBottom: p.spacing(3)
    },
    titleTextRight: {
        color: '#254CFF',
        fontSize: '16px',
        fontWeight: 'bold',
        // width: '500px',
        textAlign: 'left',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '15px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '13px',
            width: '350px',
        },
    },
    titleTextLeft: {
        color: '#254CFF',
        fontSize: '16px',
        fontWeight: 'bold',
        // width: '500px',
        textAlign: 'right',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '15px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '13px',
            width: '350px',
        },
    },
    contentText: {
        color: '#B3B3B3',
        fontSize: '16px',
        width: '500px',
        textAlign: 'left',
        display: 'inline',
        paddingTop: p.spacing(3),
        [p.breakpoints.down('md')]: {
            fontSize: '15px',
            width: '100%',
        },
        [p.breakpoints.down('sm')]: {
            fontSize: '14px',
            width: '100%',
        },
        [p.breakpoints.down('xs')]: {
            fontSize: '13px',
            width: '350px',
        },
    },
}))

const OurTeam = () => {

    const classes = useStyles()

    const listMember = [
        {
            id: 1,
            title: "Erman Tjiputra",
            image: "/images/members/erman-tjiputra.jpg",
            description: 'The Doer'
        },
        {
            id: 2,
            title: "Quang Tran",
            image: "/images/members/quang-tran.jpg",
            description: 'The Analyst'
        },
        {
            id: 3,
            title: "Anh Nguyen",
            image: "/images/members/anh-nguyen.jpg",
            description: 'The Roboticist'
        },
        {
            id: 4,
            title: "Chien Duong",
            image: "/images/members/chien-duong.jpg",
            description: 'The Dreamweaver'
        },
        {
            id: 5,
            title: "Tu Phan",
            image: "/images/members/tu-phan.jpg",
            description: 'The Mechanic'
        },
        {
            id: 6,
            title: "Pho Huynh",
            image: "/images/members/pho-huynh.jpg",
            description: 'The Executor'
        },
        {
            id: 7,
            title: "Kim Tran",
            image: "/images/members/kim-tran.jpg",
            description: 'The Dreamer'
        },
        {
            id: 8,
            title: "Truong Le",
            image: "/images/members/truong-le.jpg",
            description: 'The Binary Beast'
        },
        {
            id: 9,
            title: "Phat Le",
            image: "/images/members/phat-le.jpg",
            description: 'The Frontier'
        },
        {
            id: 10,
            title: "Phu Nguyen",
            image: "/images/members/phu-nguyen.jpg",
            description: 'The Creator'
        },
        {
            id: 11,
            title: "Minh Tran",
            image: "/images/members/minh-tran.jpg",
            description: 'The Explorer'
        },
        {
            id: 12,
            title: "Tuong Do",
            image: "/images/members/tuong-do.jpg",
            description: 'The Investigator'
        },
        {
            id: 11,
            title: "Huong Nguyen",
            image: "/images/members/huong-nguyen.jpg",
            description: 'The Behinder'
        },
    ]

    return (
        <div id="our-team" className={classes.root}>
            <div className={classes.contentRoot}>
                <Typography className={classes.title}>
                    Our Team
                </Typography>
                <SliderList slidesToShow={4} items={listMember}/>
            </div>
        </div>
    )
}

export default OurTeam
