import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar } from '@material-ui/core'
import { Link} from 'react-scroll'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  toggleMenu: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.down(1280)]: {
      float: 'right',
      padding: '8px 0px',
      display: 'inline-block',
      cursor: 'pointer',
    },
  },
  inputCheckbox: {
    display: 'none',
    [theme.breakpoints.down(1280)]: {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      background: 'none',
    },
  },
  nav: {
    width: '100%',
    float: 'right',
    [theme.breakpoints.down(1280)]: {
      display: 'none',
      position: 'absolute',
      right: '0px',
      top: '53px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '0px',
      zIndex: 99,
    }
  },
  navMenu: {
    float: 'right',
    [theme.breakpoints.down(1280)]: {
      width: 'auto',
    }
  },
  navItem: {
    float: 'left',
    listStyle: 'none',
    '&:last-child': {
      paddingRight: '0px',
    },
    [theme.breakpoints.down(1280)]: {
      float: 'none',
      paddingRight: '20px',
      width: '100%',
      display: 'table',
    },
  },
  navLink: {
    color: '#F2F2F2',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '18px',
    padding: '25px 20px',
    display: 'inline-block',
    transition: 'all 0.5s ease 0s',
    '&:hover': {
      color: '#254CFF',
      transition: 'all 0.5s ease 0s',
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: '15px',
      padding: '10px 20px',
      display: 'block',
      borderBottom: '1px solid rgba(225,225,225,0.1)',
    },
    cursor: 'pointer',
  },
  navLinkActive: {
    color: '#254CFF',
  },
  toggleListMenu: {
    display: 'table',
    width: '25px',
    float: 'right',
  },
  toggleListMenuItem: {
    width: '100%',
    height: '3px',
    listStyle: 'none',
    backgroundColor: '#FFFFFF',
    marginBottom: '4px',
    '&: last-child': {
      marginBottom: '0px'
    },
    cursor: 'pointer',
  },
  hamburger: {
    position: 'absolute',
    top: '60px',
    right: 0,
    transition: 'opacity 0.5s ease-out',
    opacity: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'none',
  },
  toggled: {
    opacity: 1,
    display: 'block',
  }

}))

const Topbar = props => {
  const { className, ...rest } = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(1280))
  const classes = useStyles()

  const [check, setCheck] = useState(false)

  const handleUnCheck = () => {
    setCheck(false)
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logo/logo.png"
          />
        </RouterLink>

        <label htmlFor="toggle-1" onClick={() => setCheck(!check)} className={classes.toggleMenu}>
          <ul className={classes.toggleListMenu}>
            <li className={classes.toggleListMenuItem}></li>
            <li className={classes.toggleListMenuItem}></li>
            <li className={classes.toggleListMenuItem}></li>
          </ul>
        </label>
        <input className={classes.inputCheckbox} type="checkbox" id="toggle-1" />

        <nav className={`${matches ? classes.hamburger : classes.nav} ${matches && check ? classes.toggled : ''}`}>
          <ul className={classes.navMenu}>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="about">About</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="choose-your-adventure">Choose Your Adventure</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="how-it-works">How It Works</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="features">Features</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="hardware">Technical</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="future-works">Future Works</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="reference">Reference</Link> </li>
            <li className={classes.navItem}> <Link onClick={handleUnCheck} activeClass={classes.navLinkActive} spy={true} smooth={true} offset={-200} duration={500} className={classes.navLink} to="contact">Contact</Link> </li>
          </ul>
        </nav>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
